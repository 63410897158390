import * as AlertDialog from "@radix-ui/react-alert-dialog";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { toTitleCase } from "../../../utils/toTitleCase";
import { useEffect, useState } from "react";
import { cleanDispositionString, getSeverityString } from "./utils";
import { messageStatusSmall } from "../../../constants/classnames";
import { TextUs } from "./MessageBoxes";
import { AutoExpungementNote } from "./AutoExpungementNote";

const greyRow = "flex justify-between mb-4 p-2 bg-gray-100";
const valueDiv = "flex-1 w-44 font-semibold";
const options = { year: "numeric", month: "long", day: "numeric" };

export function CaseDetailsModalUT({
  open,
  setOpen,
  setOpenMessageUs,
  data,
  receivedAnyMessage,
  autoExpungementEligibleNow,
  hasFreshworks,
}) {
  let [caseStatus, setCaseStatus] = useState("");
  let [isClearableNow, setIsClearableNow] = useState(false);
  let [notEligibleNow, setNotEligibleNow] = useState("");
  let [reasons, setReasons] = useState("");
  // const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (data.expungementEligibleNow) {
      setCaseStatus("that this case may be eligible for expungement");
      setIsClearableNow(true);
    } else if (data.expungementEligibleLater) {
      setCaseStatus(
        "that this case is not eligible for expungement now, due to you being in a waiting period. " +
          "Expungement eligibility is dynamic and can be impacted by additional criminal activity. " +
          "However, if you obtain no additional charges, this record may become eligible after ",
      );
      setIsClearableNow(false);
      setNotEligibleNow(
        "Would you like to receive a reminder from Rasa when your waiting period is complete?",
      );
    } else if (data.needMoreInfo) {
      setCaseStatus("we need more information to determine eligibility for this case");
      setIsClearableNow(false);
      setNotEligibleNow(
        "If you would like one of our team members to manually determine your eligibility, ",
      );
    } else {
      //  if we get here, everything has been false; not eligible
      setCaseStatus("that this case is not eligible for clearance");
      setIsClearableNow(false);
      setNotEligibleNow("If you have a question about these results,");
      setReasons(data.reasons);
    }
  }, [data]);
  const handleMessageUsClick = () => {
    //   todo record this click
    setOpenMessageUs(true);
  };

  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="fixed inset-0 bg-gray-300 opacity-50" />
        <AlertDialog.Content
          className={`fixed left-[50%] top-[50%] max-h-[95vh] w-full translate-x-[-50%] translate-y-[-50%] overflow-auto rounded-lg bg-white py-6 shadow-xl focus:outline-none data-[state=open]:animate-contentShow  md:max-w-xl`}
        >
          <div className="flex h-[95vh] flex-col">
            <div className="pb-6 pr-6">
              <XMarkIcon
                className="float-right h-9 w-9 cursor-pointer text-rasapink"
                onClick={() => setOpen(false)}
              />
            </div>
            <AlertDialog.Title className="mb-4 px-6 font-serif text-2xl">
              {data.violation}
            </AlertDialog.Title>
            <div className="-mt-2 ml-6 font-bold">Case Number: {data.caseNumber}</div>
            <div className="mb-6 ml-6 font-bold">Location: {data.court}</div>
            <AlertDialog.Description className="mb-6 px-6">
              Our preliminary screening indicates {caseStatus}
              {!isClearableNow && data.expungementEligibleLater ? (
                <>
                  <b>{data.expungementEligibleDate}.</b>
                  <div className="mt-4">
                    To learn more about Utah's waiting periods,{" "}
                    <span className="text-rasapink underline">
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.rasa-legal.com/utah/utahs-courts-expungement-process/"
                      >
                        click here
                      </a>
                    </span>
                  </div>
                </>
              ) : (
                <>.</>
              )}
              {reasons && (
                <div>
                  <b>Reason(s):</b> {reasons}.
                </div>
              )}
              {autoExpungementEligibleNow && <AutoExpungementNote />}
            </AlertDialog.Description>
            {receivedAnyMessage ? (
              <div className={messageStatusSmall}>
                <div>We have received your request for contact.</div>
              </div>
            ) : (
              <>
                <AlertDialog.Description className="mb-6 px-6">
                  {isClearableNow ? (
                    <>
                      Rasa can help you clear your eligible records for just $250 per case. If you'd
                      like to retain Rasa to represent you on this Utah matter, please{" "}
                    </>
                  ) : (
                    <>{notEligibleNow} </>
                  )}
                  <Link onClick={handleMessageUsClick}>
                    <span className="text-rasapink underline">click here to message us.</span>
                  </Link>
                </AlertDialog.Description>
              </>
            )}
            {hasFreshworks && <TextUs />}
            <div>
              <div className="px-6">
                {data.charges.map((charge, index) => {
                  let recordType = "--";
                  if (charge.disposition) {
                    if (charge.disposition.toLowerCase().includes("guilty")) {
                      recordType = "conviction";
                    } else {
                      recordType = cleanDispositionString(charge.disposition);
                    }
                  }
                  return (
                    <div key={`charge-${data.case_number}-${index}`} className="mb-12">
                      <div className="mb-2 mt-6 font-bold">Charge {index + 1}</div>
                      <div className={greyRow}>
                        <div className="flex-1">Violation Description</div>
                        <div className="mb-2 mt-6 flex-1"> </div>
                        <div className={valueDiv}>
                          {charge.description
                            ? toTitleCase(charge.description.toLowerCase())
                            : "--"}
                        </div>
                      </div>
                      <div className={greyRow}>
                        <div className="flex-1">Severity</div>
                        <div className="mb-2 mt-6 flex-1"> </div>
                        <div className={valueDiv}>
                          {charge.severity ? getSeverityString(charge.severity) : "--"}
                        </div>
                      </div>
                      <div className={greyRow}>
                        <div className="flex-1">Judgment Date</div>
                        <div className="mb-2 mt-6 flex-1"> </div>
                        <div className={valueDiv}>
                          {charge.disposition_date
                            ? new Date(charge.disposition_date).toLocaleDateString("en-US", options)
                            : "--"}
                        </div>
                      </div>
                      <div className={greyRow}>
                        <div className="flex-1">Type of Record</div>
                        <div className="mb-2 mt-6 flex-1"> </div>
                        <div className={valueDiv}>{toTitleCase(recordType)}</div>
                      </div>
                      {/*<div className={greyRow}>*/}
                      {/*  <div>Location</div>*/}
                      {/*  <div>some data</div>*/}
                      {/*</div>*/}
                      <div className={greyRow}>
                        <div className="flex-1">Violation Code</div>
                        <div className="mb-2 mt-6 flex-1"> </div>
                        <div className={valueDiv}>{charge.code ? charge.code : "--"}</div>
                      </div>
                      {/*<div className={greyRow}>*/}
                      {/*  <div>Probation Agency</div>*/}
                      {/*  <div>some data</div>*/}
                      {/*</div>*/}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
}
