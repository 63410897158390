import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { Checkbox } from "../../../../components/checkbox";
import { MANDATORY_SURVEY, OPTIONAL_SURVEY } from "../../../../constants/surveyTypes";
import { makePayment, updatePerson } from "../../../../api/secureApi";
import { adjustState } from "./utils";
import { handleError } from "../../../../utils/handleError";
import { container3 } from "../../../../constants/classnames";
import { doGtag } from "../payment/payment";
import { isFreeUtm, PARTNERSHIP_, REFERRALCLIENT } from "../../../../constants/utmCodes";

export function InterestedInfo() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [currentValues, setCurrentValues] = useState([]);
  const [genericErrorMessage, setGenericErrorMessage] = useState("");
  const { handleSubmit } = useForm({ mode: "all" });

  const onSubmit = () => {
    const currentMandatorySurvey = state.surveys.find((survey) => survey.type === MANDATORY_SURVEY);
    let currentOptionalSurvey = state.surveys.find((survey) => survey.type === OPTIONAL_SURVEY);
    if (currentOptionalSurvey) {
      currentOptionalSurvey.answers.interested_info = [...currentValues];
    } else {
      currentOptionalSurvey = {
        type: OPTIONAL_SURVEY,
        answers: {
          interested_info: [...currentValues],
        },
      };
    }
    const stateToSubmit = {
      profile_id: state.profile_id,
      surveys: [currentOptionalSurvey, currentMandatorySurvey],
    };
    adjustState(state, stateToSubmit);
    // eslint-disable-next-line no-console
    console.log("stateToSubmit in interested_info", stateToSubmit);

    updatePerson(stateToSubmit)
      .then((response) => {
        const utm_code = response.utm_code;
        const isDirectMail = utm_code?.startsWith("direct-mail_apr24_");
        const isFree = isFreeUtm(utm_code);
        const hasPeerReferrer = response.has_peer_referrer;
        if (utm_code && (isDirectMail || isFree || hasPeerReferrer)) {
          let promotion_code = utm_code.replaceAll("-", "").replaceAll("_", "");
          if (isDirectMail) {
            promotion_code = "FIVEOFFSKIP";
          } else if (hasPeerReferrer) {
            // NOTE: this is different from the utmFree group because we're giving the option
            // to pay $10
            promotion_code = REFERRALCLIENT;
          } else if (utm_code.startsWith(PARTNERSHIP_)) {
            promotion_code = "partnership";
          }
          makePayment({ promotion_code: promotion_code })
            .then((response) => {
              if (window.gtag) {
                doGtag(0);
              }
              navigate("/register/verification", { state: response });
            })
            .catch((error) => {
              // eslint-disable-next-line no-console
              console.log(error);
              navigate("/register/payment", { state: { ...response, amount: "5" } });
            });
        } else {
          navigate("/register/payment", { state: { ...response, amount: "5" } });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error in interested_info", error);
        handleError(error, setGenericErrorMessage, navigate);
      });
  };

  return (
    <>
      <section id="heading">
        <div className="container mx-auto mt-10 flex max-w-3xl flex-col px-4">
          <h2 className="font-serif text-xl">
            11. Would you be interested in learning about any of the following? (please select all
            that apply)
          </h2>
        </div>
      </section>
      <section id="form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={container3}>
            <div className="pb-4">
              <Checkbox
                value="Access to health care or insurance"
                currentValues={currentValues}
                setCurrentValues={setCurrentValues}
              />
              <Checkbox
                value="Housing support"
                currentValues={currentValues}
                setCurrentValues={setCurrentValues}
              />
              <Checkbox
                value="Employment support or fair chance hiring opportunities"
                currentValues={currentValues}
                setCurrentValues={setCurrentValues}
              />
              <Checkbox
                value="Scholarship opportunities to pursue higher education"
                currentValues={currentValues}
                setCurrentValues={setCurrentValues}
              />
            </div>
            <div className="mb-16 flex flex-col justify-center">
              {genericErrorMessage && (
                <div className="mb-2  text-center text-sm text-red-600" id="email-error">
                  {genericErrorMessage}
                </div>
              )}
              <button
                type="submit"
                className="mb-4 w-full rounded-full bg-rasapink px-24 py-4 font-bold text-white hover:shadow-xl"
              >
                next
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
}
