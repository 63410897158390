// partnership utm codes
export const utmFree = ["davis-tech", "honestjobs_jul24_az", "demo"];
export const REFERRAL_CLIENT = "referral-client";
export const REFERRALCLIENT = "referralclient";

export const PARTNERSHIP_ = "partnership_";

export const isFreeUtm = (utm_code) => {
  return utmFree.includes(utm_code) || (utm_code && utm_code.startsWith(PARTNERSHIP_));
};
