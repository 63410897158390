import RasaLogo from "@images/rasa-logo.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { WarningModal } from "./WarningModal";
import { getEligibility, getMessages, getPerson, hasSeenResults } from "../../../api/secureApi";
import { MessageUsModal } from "./MessageUsModal";
import { MANDATORY_SURVEY } from "../../../constants/surveyTypes";
import { checkTokenExpired, handleError } from "../../../utils/handleError";
import { NoResultsModal } from "./NoResultsModal";
import { flexCenter } from "../../../constants/classnames";
import { ContactMeModal } from "./ContactMeModal";
import { showMenu } from "../login/login";
import { WelcomeBox } from "./MessageBoxes";
import { loadingResults } from "../../../components/loading";
import { AZ, UT } from "../../../constants/usStates";
import { CardSectionUT } from "./CardSectionUT";
import { CardSectionAZ } from "./CardSectionAZ";

export const MISDEMEANOR = "misdemeanor";
export const FELONY = "felony";
export const INFRACTION = "infraction";
export const TRAFFIC = "traffic";
export const PARKING = "parking";
export const INELIGIBLE = "ineligible";
export const NOT_APPLICABLE = "not applicable";
export const ELIGIBLE = "eligible";
export const UNKNOWN = "unknown";
export const EXPUNGEMENT = "expungement";
export const AUTO_EXPUNGEMENT = "auto-expungement";
export const CONTACT_TIME = "contact you soon.";
export const PATIENT_MESSAGE = "Please be patient, as our company is still small.";

export const Results = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [profileId, setProfileId] = useState("");
  const [person, setPerson] = useState({});
  const [clientStatus, setClientStatus] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [arizonaCounties, setArizonaCounties] = useState([]);
  const [openMessageUs, setOpenMessageUs] = useState(false);
  const [openContactMe, setOpenContactMe] = useState(false);
  const [openNoResults, setOpenNoResults] = useState(false);
  const [openCases, setOpenCases] = useState(false);
  const [onProbationOrParole, setOnProbationOrParole] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  // if at least one case is eligible for AZ SEALING, the client can get a free consultation
  const [oneIsEligible, setOneIsEligible] = useState(false);
  const [ineligibleReasons, setIneligibleReasons] = useState("");
  // user can only request contact with no message if they are eligible
  const [receivedContactRequest, setReceivedContactRequest] = useState(false);
  const [receivedMessage, setReceivedMessage] = useState(false);
  const [receivedNoResultsMessage, setReceivedNoResultsMessage] = useState(false);
  // using receivedAnyMessage to hide message ability within record card
  const [receivedAnyMessage, setReceivedAnyMessage] = useState(false);
  const [hasFreshworks, setHasFreshworks] = useState(false);
  const [criminalCount, setCriminalCount] = useState(0);
  const [trafficCount, setTrafficCount] = useState(0);

  const numOfRecords = data.length;
  const recordOrRecords = numOfRecords === 1 ? "record" : "records";

  const updateLocalSurveys = useCallback(
    (responseFromUpdate) => {
      const currentMandatorySurvey = responseFromUpdate.surveys.find(
        (survey) => survey.type === MANDATORY_SURVEY,
      );
      const answers = currentMandatorySurvey.answers;

      if (responseFromUpdate.birth_date) {
        const dateArray = responseFromUpdate.birth_date.split("-");
        const year = dateArray[0];
        const month = dateArray[1];
        const day = dateArray[2];
        const dateString = `${month}/${day}/${year}`;
        setBirthDate(dateString);
      }

      if (answers.arizona_counties) {
        setArizonaCounties(responseFromUpdate.arizona_counties);
      }
      const openCasesTemp = answers.open_cases === "yes";
      setOpenCases(openCasesTemp);

      const onProbationOrParoleTemp = answers.on_probation_or_parole === "yes";
      setOnProbationOrParole(onProbationOrParoleTemp);

      if (isFirstRender) {
        setOpenWarning(openCasesTemp || onProbationOrParoleTemp);
        hasSeenResults()
          .then()
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.log("error setting has seen results", error);
          });
        setIsFirstRender(false);
      }
    },
    [isFirstRender],
  );

  useEffect(() => {
    setIsLoading(true);
    const profile_id = localStorage.getItem("profile_id");
    setProfileId(profile_id);
    getEligibility()
      .then((eligibility) => {
        setData(eligibility);
        if (eligibility.length === 0 && !eligibility.has_seen_results) {
          setOpenNoResults(true);
        }
        getPerson()
          .then((response) => {
            setPerson(response);
            updateLocalSurveys(response);
            setClientStatus(response.client_status);
            setHasFreshworks(!!response.freshworks_id);
            getMessages()
              .then((response) => {
                response.forEach((obj) => {
                  if (obj.type === "contact_me_no_results") {
                    setReceivedNoResultsMessage(true);
                    setReceivedAnyMessage(true);
                    // todo omg, really jen?
                    setOpenNoResults(false);
                  } else if (obj.type === "contact_me") {
                    setReceivedContactRequest(true);
                    setReceivedAnyMessage(true);
                  } else if (obj.type === "message") {
                    setReceivedMessage(true);
                    setReceivedAnyMessage(true);
                  }
                });
                setIsLoading(false);
              })
              .catch((error) => {
                // eslint-disable-next-line no-console
                console.log("error getting messages", error);
                setIsLoading(false);
              });
          })
          .catch((error) => {
            if (
              error?.response?.data?.detail &&
              typeof error?.response?.data?.detail === "string"
            ) {
              checkTokenExpired(error, navigate);
            }
            setIsLoading(false);
          });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log("error----", error);
        handleError(error, () => ({}), navigate);
        setIsLoading(false);
      });
  }, [updateLocalSurveys, navigate]);

  const handleLogoutClick = () => {
    localStorage.setItem("token", "");
    localStorage.setItem("profile_id", "");
    navigate("/login");
  };

  const showConsultBox =
    oneIsEligible &&
    !openCases &&
    !onProbationOrParole &&
    !receivedContactRequest &&
    !receivedMessage;

  return (
    <div className="mb-12">
      <div className="flex justify-between">
        <div>
          {showMenu(clientStatus) && (
            <Link onClick={() => navigate("/menu", { state: state })}>
              <div className="mt-4 px-6 text-base tracking-tighter text-rasapink">menu</div>
            </Link>
          )}
        </div>
        <div>
          <Link onClick={handleLogoutClick}>
            <div className="mt-4 px-6 text-base tracking-tighter text-rasapink">log out</div>
          </Link>
        </div>
      </div>
      <div className={flexCenter}>
        <RasaLogo className="h-9" />
      </div>
      {isLoading ? (
        <div className="mt-16 flex justify-center">{loadingResults}</div>
      ) : (
        <>
          <WelcomeBox
            birthDate={birthDate}
            handleErrorMessageUsClick={setOpenMessageUs}
            hasFreshworks={hasFreshworks}
            ineligibleReasons={ineligibleReasons}
            numOfRecords={numOfRecords}
            criminalCount={criminalCount}
            trafficCount={trafficCount}
            openCases={openCases}
            onProbationOrParole={onProbationOrParole}
            person={person}
            receivedAnyMessage={receivedAnyMessage}
            receivedContactRequest={receivedContactRequest}
            receivedMessage={receivedMessage}
            receivedNoResultsMessage={receivedNoResultsMessage}
            recordOrRecords={recordOrRecords}
            showConsultBox={showConsultBox}
            setOpenContactMe={setOpenContactMe}
            setOpenMessageUs={setOpenMessageUs}
            usState={person?.us_state}
          />
          <>
            {person?.us_state === AZ && (
              <CardSectionAZ
                arizonaCounties={arizonaCounties}
                data={data}
                openCases={openCases}
                openMessageUs={openMessageUs}
                onProbationOrParole={onProbationOrParole}
                receivedAnyMessage={receivedAnyMessage}
                setOneIsEligible={setOneIsEligible}
                setOpenMessageUs={setOpenMessageUs}
                hasFreshworks={hasFreshworks}
              />
            )}
            {person?.us_state === UT && (
              <CardSectionUT
                data={data}
                ineligibleReasons={ineligibleReasons}
                openCases={openCases}
                openMessageUs={openMessageUs}
                onProbationOrParole={onProbationOrParole}
                receivedAnyMessage={receivedAnyMessage}
                setIneligibleReasons={setIneligibleReasons}
                setOneIsEligible={setOneIsEligible}
                setOpenMessageUs={setOpenMessageUs}
                hasFreshworks={hasFreshworks}
                setCriminalCount={setCriminalCount}
                setTrafficCount={setTrafficCount}
              />
            )}
          </>
          <WarningModal
            openWarning={openWarning}
            setOpenWarning={setOpenWarning}
            openCasesBool={openCases}
            probationOrParoleBool={onProbationOrParole}
          />
          <MessageUsModal
            open={openMessageUs}
            setOpen={setOpenMessageUs}
            profileId={profileId}
            setReceivedMessage={setReceivedMessage}
            setReceivedAnyMessage={setReceivedAnyMessage}
          />
          <NoResultsModal
            open={openNoResults}
            setOpen={setOpenNoResults}
            profileId={profileId}
            setReceivedNoResultsMessage={setReceivedNoResultsMessage}
            setReceivedAnyMessage={setReceivedAnyMessage}
          />
          <ContactMeModal
            open={openContactMe}
            setOpen={setOpenContactMe}
            profileId={profileId}
            setReceivedContactRequest={setReceivedContactRequest}
            setReceivedAnyMessage={setReceivedAnyMessage}
          />
        </>
      )}
    </div>
  );
};
